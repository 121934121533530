<template>
    <v-row class="margen">
      <v-col
        v-for="(area,i) in areas"
        :key="i"
        cols="12"
        sm="4"
      >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              elevation="24"
              class="info-card"
              max-width="344"
              color="#f6f4f5"
            >
              <v-avatar
                class="profile "
                size="344"
                rounded
              >

              <v-img :src="area.ref" transition="fade-transition"></v-img>
              </v-avatar>

              <v-card-text
              >
                <h2 class="text-h6 mt-8 primary--text">
                {{area.nombre}}
                </h2>
              </v-card-text>

              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="#030303"
                >
                  <v-btn
                    @click="verArea(area.nombre)"
                  >
                    See more info
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
</template>

<script>

  export default{
    name:'Research',
    data () {
      return {
        areas: [
          {
            nombre: 'Mining and Manufacturing Applications',
            ref: require('../assets/Area/mineria.svg')
          },
          {
            nombre: 'Biomedical Image Processing and Classification',
            ref: require('../assets/Area/imagenes.svg')
          },
          {
            nombre: 'Neuroscience',
            ref: require('../assets/Area/neurociencia.svg')
          }
        ],
      }
    },

    methods:{
      verArea(nombre){
        this.$router.push("/ResearchAreas/" + nombre.replace(/\s+/g, '_'))
      }
    }

  }

</script>

<style scoped>
  .margen{
    margin:20px auto 132px;
  }
  .contenido{
    animation: name1 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s forwards;
    opacity: 0;
  }
  @keyframes name1 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  .info-card{
    margin:auto auto;
    width:344px;
    height:500px;
    opacity: 0;
    animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @keyframes slide-top {
    0% {
      opacity: 0;
      transform: translateY(1000px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
</style>